<template>
  <div class="richtext-container" v-loading="loading">
    <div>
      <tinymce
        :height="400"
        v-model="form.value"
        :value="form.value"
        ref="content"
      />
      <el-row style="padding: 15px 0; text-align: center">
        <el-button type="primary" @click="handleSave">保存</el-button>
      </el-row>
    </div>
  </div>
</template>

<script>
import Tinymce from "@/components/Tinymce";
import request from "@/utils/request";

export default {
  components: { Tinymce },
  data() {
    return {
      loading: false,
      form: {
        key: "",
        value: ""
      },
      listQuery: { key: "" }
    };
  },
  created() {
    this.prepare(this.$route);
  },
  watch: {
    $route(newVal, oldVal) {
      this.prepare(newVal);
    }
  },
  methods: {
    getSetting() {
      this.loading = true;
      request({
        url: "/api/backend/settings/get",
        method: "get",
        params: this.listQuery
      })
        .then(res => {
          this.resetForm();
          this.$refs.content.setContent("");
          if (res.data.value) {
            this.form.key = this.listQuery.key;
            this.form.value = res.data.value;
            this.$refs.content.setContent(this.form.value);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    resetForm() {
      this.form.value = null;
    },
    prepare(route) {
      if (route.name === "about_us") {
        this.listQuery.key = "about_us";
        this.form.key = "about_us";
      } else if (route.name === "user_service_agreement") {
        this.listQuery.key = "user_service_agreement";
        this.form.key = "user_service_agreement";
      } else if (route.name === "user_privacy_agreement") {
        this.listQuery.key = "user_privacy_agreement";
        this.form.key = "user_privacy_agreement";
      }
      this.getSetting();
    },
    handleSave() {
      // this.$confirm("确定要保存, 是否继续?", "提示", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      //   type: "warning"
      // })
      //   .then(() => {
          let data = {};
          data[this.form.key] = {
            value: this.form.value
          };
          request({
            url: "/api/backend/settings/store",
            method: "post",
            data: data
          }).then(() => {
            this.$message({
              type: "success",
              message: "保存成功!"
            });
            this.getSetting();
          });
        // })
        // .catch(() => {
        //   this.$message({
        //     type: "info",
        //     message: "已取消"
        //   });
        // });
    }
  }
};
</script>

<style lang="scss" scoped>
.richtext-container {
  width: 90%;
  margin: 0 auto;
  margin-top: 50px;
  display: flex;

  div {
    flex: 1;
  }
}
</style>
